import { Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const Index = () =>
  <Layout>
    <Helmet>
      <title>Contact</title>
    </Helmet>

    <Heading>Contact us!</Heading>

    <Text>Need support? Have a question? Feel free to reach out to us at <Link href="mailto:support@image-watch.com">support@image-watch.com</Link>.</Text>

  </Layout>;

export default Index;